.div_payment-cardElement{
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    height: auto;
    width: 100%;
}
.form_payment-cardElement{
    width: 100%;
    background: #e4e3e1;
    border-bottom: 1px;
    border-bottom-color: #fd8d1d;
    
}
.form-label{
	width:72px;
	font-weight:bold;
	display:inline-block;
    padding: 10px;
    color: #fd8d1d;
    font-family: 'system-ui';
}
.form_payment-btn{
    width: max-content;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    background-color: #1565c0;
    color: #f9fbe7;
}
.form_payment-btn:hover{
    cursor: pointer;
    background-color: #1976d2;
}

section {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 112px;
    border-radius: 6px;
    justify-content: space-between;
}

.product {
    display: flex;    
}

.descriptionStripe {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pStripe {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.154px;
    color: #242d60;
    height: 100%;
    width: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.imgStripe {
    border-radius: 6px;
    margin: 10px;
    width: 54px;
    height: 57px;
}

.h3Stripe, .h5Stripe {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.154px;
    color: #242d60;
    margin: 0;
}

.h5Stripe {
    opacity: 0.5;
}

.buttonStripe {
    height: 36px;
    background: #556cd6;
    color: white;
    width: 100%;
    font-size: 14px;
    border: 0;
    font-weight: 500;
    cursor: pointer;
    letter-spacing: 0.6;
    border-radius: 0 0 6px 6px;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}

.buttonStripe:hover {
    opacity: 0.8;
}