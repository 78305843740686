body {
    background-color: #0F5986;
    margin: 0px;
 } 
.slider {
	width: 100%;
	max-width: 300px;
	height: 400px;
	padding: 20px 0 40px;
	position: relative;
	overflow: hidden;
	margin: 0 auto 50px;
  }
  .slider__container {
	width: 300%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	transform: translateX(calc(-100% * 0 / 3));
	transition: transform 400ms ease;
  }
  .second-img {
	transform: translateX(calc(-100% * 1 / 3));
	transition: transform 400ms ease;
  }
  .third-img {
	transform: translateX(calc(-100% * 2 / 3));
	transition: transform 400ms ease;
  }
  .slider__imgs {
	width: calc(100% / 3);
	height: 100%;
	object-fit: contain;
  }
  .slider__prev,
  .slider__next {
	position: absolute;
	width: 40px;
	aspect-ratio: 1;
	border-radius: 50%;
	background-color: #1565c0;
	color: #f9fbe7;
	font-size: 1.6rem;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	z-index: 1;
  }
  .slider__prev {
	left: 0;
  }
  .slider__next {
	right: 0;
  }
  .puntitos-container {
	display: flex;
	gap: 40px;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
  }
  .puntitos {
	width: 20px;
	aspect-ratio: 1;
	border-radius: 50%;
	background-color: #ccc;
	cursor: pointer;
  }
  .puntitos__active {
	background-color: #1565c0;
  }
  
.pagination-prev-next {
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    width: 40px;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E1A73E;
    border-radius: 8px;
}
.pagination-number-container {
    display: flex;
    gap: 20px;
    list-style: none;
    padding-left: 0;
}

.pagination-container-1 {
    box-shadow: none;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;    
    text-align: center;
    gap: 5px;
}
.page-active {
    background-color: #E1A73E;
    color: #fff;
}
.pagination-number {
    font-size: 20px;
    cursor: pointer;
    width: 40px;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: #fff;
}
